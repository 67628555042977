// Set default icon color
//
// To add a new color, create a new case with the value and
// name of the color.

export const Logo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="24"
      fill="none"
      viewBox="0 0 54 24"
    >
      <path
        fill="#0A0A29"
        d="M53.6185 10.1575H49.4994C49.3977 10.1575 49.3468 10.2082 49.3468 10.3097V17.5313C49.3468 18.5962 49.5882 19.3635 50.0716 19.8318C50.5543 20.3015 51.3045 20.5353 52.3216 20.5353H53.4273C53.6811 20.5353 53.809 20.6622 53.809 20.9159V23.2732C53.809 23.5269 53.6817 23.6538 53.4273 23.6538C52.6131 23.7045 52.0286 23.7299 51.6731 23.7299C49.7408 23.7299 48.285 23.3816 47.3065 22.6842C46.3273 21.9876 45.8384 20.7006 45.8384 18.8245V0.381042C45.8384 0.12734 45.9649 0.000488281 46.2193 0.000488281H48.9651C49.2196 0.000488281 49.3468 0.12734 49.3468 0.381042V7.00062C49.3468 7.1021 49.3977 7.15284 49.4994 7.15284H53.6185C53.8722 7.15284 54.0001 7.27969 54.0001 7.53339V9.77695C54.0001 10.0306 53.8729 10.1575 53.6185 10.1575Z"
      />
      <path
        fill="#0A0A29"
        d="M22.4947 12.4387C22.4727 12.8865 22.4617 13.3671 22.4617 13.8841C22.4617 16.0139 22.767 17.8268 23.3769 19.3223C24.0123 20.8184 24.9654 21.9718 26.2376 22.7829C27.5084 23.5948 29.0721 24 30.9287 24C32.5812 24 34.0617 23.6702 35.3717 23.0113C36.681 22.3523 37.6911 21.452 38.4035 20.311C38.5046 20.1341 38.4668 19.9689 38.2894 19.8167L36.1157 18.3719C35.9122 18.195 35.7471 18.2327 35.6199 18.4858C34.6029 20.0327 33.1533 20.8054 31.2726 20.8054C29.9756 20.8054 28.9015 20.5202 28.0502 19.9497C27.1982 19.3792 26.633 18.5872 26.3531 17.5731C26.2004 17.1171 26.1241 16.4582 26.1241 15.5956C26.1241 15.4948 26.175 15.4434 26.2768 15.4434H38.4427C38.6965 15.4434 38.8244 15.3165 38.8244 15.0628C38.8429 14.1207 38.8271 13.2465 38.7783 12.4387H22.4947Z"
      />
      <path
        fill="#0A0A29"
        d="M15.4454 10.6896C15.4454 8.63595 14.8354 7.00677 13.6148 5.80272C12.3943 4.59934 10.792 3.99731 8.65686 3.99731C6.5217 3.99731 4.9064 4.69465 3.81372 6.08865C3.78759 6.13939 3.75596 6.15173 3.71814 6.12636C3.68032 6.10167 3.66106 6.06396 3.66106 6.01253V4.64323C3.66106 4.38952 3.53316 4.26267 3.27942 4.26267H0.381647C0.127216 4.26267 0 4.39021 0 4.64323V23.3534C0 23.6071 0.127216 23.734 0.381647 23.734H3.27942C3.53385 23.734 3.66106 23.6071 3.66106 23.3534V11.3746C3.66106 10.1328 4.04271 9.11799 4.80532 8.3322C5.56792 7.54709 6.5217 7.15351 7.74159 7.15351C8.96149 7.15351 9.94002 7.54641 10.6779 8.3322C11.415 9.11799 11.7836 10.1451 11.7836 11.4123V23.3528C11.7836 23.6065 11.9108 23.7333 12.1653 23.7333H15.0637C15.3182 23.7333 15.4454 23.6065 15.4454 23.3528V10.6896Z"
      />
      <path
        fill="#FF8000"
        d="M25.7771 0.399497C26.092 0.27676 26.4592 0.151966 26.8093 0.0511707C27.4955 -0.146992 28.2128 0.247962 28.4108 0.932272C28.602 1.59258 28.241 2.28101 27.5994 2.5066C27.576 2.51551 27.5519 2.52306 27.5272 2.52991C27.2514 2.60945 26.977 2.70202 26.7061 2.80761C26.0384 3.0668 25.284 2.7411 25.0241 2.07805C24.7642 1.41499 25.0846 0.670342 25.7489 0.410468L25.7771 0.399497Z"
      />
      <path
        fill="#FF8000"
        d="M26.2753 12.4387C26.2685 12.4387 26.2619 12.4384 26.2555 12.438H26.2767L26.2753 12.4387Z"
      />
      <path
        fill="#FF8000"
        d="M26.2555 12.438C26.1669 12.4316 26.1226 12.3811 26.1226 12.2864C26.1735 11.4252 26.2877 10.7149 26.4658 10.1567C26.7195 9.24406 27.2029 8.51587 27.9153 7.97006C28.6271 7.42495 29.53 7.15273 30.6233 7.15273C31.7167 7.15273 32.676 7.451 33.4262 8.04618C34.1757 8.64203 34.6784 9.434 34.9328 10.4228C35.0339 10.8287 35.1103 11.4492 35.1618 12.2864C35.1618 12.3879 35.1109 12.4387 35.0092 12.4387H38.7768C38.7589 12.1383 38.7362 11.8462 38.7087 11.5637C38.4289 9.0795 37.6532 7.1973 36.3824 5.91645C35.1109 4.63628 33.1917 3.99585 30.624 3.99585C28.6663 3.99585 27.0008 4.50325 25.6282 5.51669C24.255 6.53082 23.3397 7.91247 22.8824 9.66164C22.6802 10.3562 22.5523 11.2826 22.496 12.438H26.2555Z"
      />
      <path
        fill="#FF8000"
        d="M19.1822 6.84151C19.319 6.49182 19.4772 6.14349 19.6532 5.80613C19.9826 5.17393 20.7638 4.92846 21.3978 5.2569C22.0318 5.58534 22.2787 6.36428 21.9486 6.99648C21.8159 7.25155 21.6963 7.51485 21.5931 7.77816C21.4638 8.10865 21.2135 8.3555 20.9123 8.48921C20.6084 8.6236 20.2529 8.64212 19.9187 8.51184C19.253 8.25333 18.923 7.50525 19.1822 6.84151Z"/>
      <path
        fill="#FF8000"
        d="M42.1146 6.88671C41.9895 6.53289 41.8423 6.17908 41.6773 5.83693C41.3678 5.19444 40.5956 4.92428 39.9513 5.23216C39.3069 5.54071 39.0353 6.31073 39.3447 6.95322C39.4692 7.21241 39.5806 7.47914 39.6755 7.74587C39.7938 8.08048 40.0365 8.33555 40.3336 8.47818C40.6334 8.62217 40.9882 8.65165 41.3266 8.53234C42.0005 8.2951 42.3525 7.55799 42.1146 6.88671Z"/>
      <path
        fill="#FF8000"
        d="M35.4694 0.399497C35.1544 0.27676 34.7872 0.151966 34.4372 0.0511707C33.7509 -0.146992 33.0337 0.247962 32.8357 0.932272C32.6445 1.59258 33.0055 2.28101 33.6471 2.5066C33.6705 2.51551 33.6946 2.52306 33.7193 2.52991C33.9951 2.60945 34.2694 2.70202 34.5404 2.80761C35.2081 3.0668 35.9624 2.7411 36.2224 2.07805C36.4823 1.41499 36.1619 0.670342 35.4976 0.410468L35.4694 0.399497Z"
      />
    </svg>
  );
};

// Set default icon color
//
// To add a new color, create a new case with the value and
// name of the color.

let fillColor;
const ColorSwitch = (propColor) => {
  switch (propColor) {
    case "orange":
      fillColor = "#FF8000";
      break;
    case "white":
      fillColor = "#FFF";
      break;
    case "red":
      fillColor = "#FF0055";
      break;
    case "darkGray":
      fillColor = "#A1A1AA";
      break;
    default:
      fillColor = "#0A0A29";
      break;
  }
  return fillColor;
};

// Icons
//
// To add an icon, create an arrow function with the name of the icon,
// give it the input value "props", call the ColorSwitch function at
// the beginning with the value "props.color" and return the svg code.

export const Add = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M21 11.001h-8v-8h-2v8H3v2h8v8h2v-8h8v-2z"/>
      </g>
    </svg>
  );
};

export const ArrowLeft = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"/>
      </g>
    </svg>
  );
};

export const ArrowRight = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M12 4l-1.413 1.413L16.173 11H4v2h12.173l-5.586 5.587L12 20l8-8-8-8z"/>
      </g>
    </svg>
  );
};

export const Close = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M20.749 4.707l-1.415-1.414-7.292 7.293-7.293-7.293-1.415 1.414L10.627 12l-7.293 7.293 1.415 1.414 7.293-7.293 7.292 7.293 1.415-1.414L13.456 12l7.293-7.293z"/>
      </g>
    </svg>
  );
};

export const Cookie = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M17 3C17 3.55228 16.5523 4 16 4C15.4477 4 15 3.55228 15 3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3Z"/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5 12C5 10.346 6.346 9 8 9C9.654 9 11 10.346 11 12C11 13.654 9.654 15 8 15C6.346 15 5 13.654 5 12ZM7 12C7 12.551 7.449 13 8 13C8.551 13 9 12.551 9 12C9 11.449 8.551 11 8 11C7.449 11 7 11.449 7 12Z"
        />
        <path d="M16.001 15C16.5533 15 17.001 14.5523 17.001 14C17.001 13.4477 16.5533 13 16.001 13C15.4487 13 15.001 13.4477 15.001 14C15.001 14.5523 15.4487 15 16.001 15Z"/>
        <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.61 9.89105C20.892 9.80005 21.201 9.83905 21.453 9.99805C21.704 10.1571 21.871 10.4191 21.908 10.7151C21.971 11.1991 22 11.6071 22 12.0001C22 17.5151 17.514 22.0001 12 22.0001C6.486 22.0001 2 17.5151 2 12.0001C2 6.67505 6.169 2.29205 11.492 2.02505C11.857 2.00405 12.203 2.18905 12.394 2.49905C12.586 2.81105 12.592 3.20205 12.41 3.51905C12.139 3.99505 12 4.49305 12 5.00005C12 6.65405 13.346 8.00005 15 8.00005C15.531 8.00005 16.045 7.85305 16.529 7.56405C16.817 7.39205 17.171 7.37505 17.474 7.52005C17.776 7.66405 17.986 7.95105 18.033 8.28205C18.172 9.26105 19.018 10.0001 20 10.0001C20.184 10.0001 20.377 9.96505 20.61 9.89105ZM4 12.0001C4 16.4111 7.589 20.0001 12 20.0001C16.411 20.0001 20 16.4111 20 12.0001C18.464 12.0001 17.095 11.1041 16.434 9.78205C15.966 9.92605 15.482 10.0001 15 10.0001C12.243 10.0001 10 7.75705 10 5.00005C10 4.74705 10.02 4.49605 10.059 4.24805C6.561 5.11505 4 8.26805 4 12.0001Z"
        />
        <path d="M21 6C21.5523 6 22 5.55228 22 5C22 4.44772 21.5523 4 21 4C20.4477 4 20 4.44772 20 5C20 5.55228 20.4477 6 21 6Z"/>
      </g>
    </svg>
  );
};

export const Done = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
      </g>
    </svg>
  );
};

export const Electricity = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0001 11.9998V5.78245L7.11499 11.9998H12.0001ZM16.8851 11.9998H12.0001V18.2172L16.8851 11.9998ZM5.05752 13.9998C4.22309 13.9998 3.75568 13.0381 4.27121 12.382L12.2137 2.27331C12.8005 1.52656 14.0001 1.94145 14.0001 2.89113V9.99981H18.9426C19.777 9.99981 20.2444 10.9615 19.7289 11.6176L11.7864 21.7263C11.1996 22.4731 10.0001 22.0582 10.0001 21.1085V13.9998H5.05752Z"
        />
      </g>
    </svg>
  );
};

export const Extent = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M9.293 10.707L5 6.414V9H3V3H9V5H6.414L10.707 9.293L9.293 10.707Z"/>
        <path d="M6.414 19H9V21H3V15H5V17.586L9.293 13.293L10.707 14.707L6.414 19Z"/>
        <path d="M15 21H21V15H19V17.586L14.707 13.293L13.293 14.707L17.586 19H15V21Z"/>
        <path d="M15 3H21V9H19V6.414L14.707 10.707L13.293 9.293L17.586 5H15V3Z"/>
      </g>
    </svg>
  );
};

export const GPS = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0013 3.06V1h-2v2.06A8.994 8.994 0 003.06 11H1v2h2.06A8.994 8.994 0 0011 20.94V23h2v-2.06A8.994 8.994 0 0020.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>
      </g>
    </svg>
  );
};

export const Ruler = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M16.3828 0L0 16.3828L7.61709 23.9999L23.9999 7.61709L16.3828 0ZM16.3828 2.75061L21.2493 7.61709L7.61709 21.2493L2.75062 16.3828L3.869 15.2644L5.95463 17.3501L7.34506 15.9899L5.2292 13.874L6.3778 12.7556L7.22415 13.602L8.58434 12.2115L7.738 11.3954L8.88661 10.2468L10.9722 12.3325L12.3324 10.9723L10.2468 8.88662L11.3954 7.73801L12.2115 8.58436L13.602 7.22416L12.7556 6.37782L13.874 5.22921L15.9899 7.34507L17.35 5.95465L15.2644 3.86901L16.3828 2.75061Z"/>
      </g>
    </svg>
  );
};

export const Saving = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M11 10C11 9.44772 11.4477 9 12 9H16C16.5523 9 17 9.44772 17 10C17 10.5523 16.5523 11 16 11H12C11.4477 11 11 10.5523 11 10Z"/>
        <path d="M9 11C9 11.5523 8.55228 12 8 12C7.44772 12 7 11.5523 7 11C7 10.4477 7.44772 10 8 10C8.55228 10 9 10.4477 9 11Z"/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0623 5.06217L7.70711 2.70701C7.07714 2.07704 6 2.52321 6 3.41411V7.10092C5.21361 7.90342 4.61888 8.89444 4.28988 9.9999H2C1.44772 9.9999 1 10.4476 1 10.9999V14.9999C1 15.5522 1.44772 15.9999 2 15.9999H5.25469C6.15007 17.2836 7.46466 18.2531 9 18.71V21H11V18.9999H16V21H18V18.71C20.8915 17.8495 23 15.1709 23 11.9999C23 8.13391 19.866 4.9999 16 4.9999H11C10.682 4.9999 10.369 5.0211 10.0623 5.06217ZM9.34739 7.17572L8 5.82833V7.91749L7.42848 8.50072C6.8649 9.07585 6.44086 9.78389 6.20679 10.5704L5.78134 11.9999H3V13.9999H6.29812L6.89506 14.8557C7.802 16.1559 9.3026 16.9999 11 16.9999H16C18.7614 16.9999 21 14.7613 21 11.9999C21 9.23848 18.7614 6.9999 16 6.9999H11C10.771 6.9999 10.5467 7.01516 10.3277 7.04448L9.34739 7.17572Z"
        />
      </g>
    </svg>
  );
};

export const Search = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={props.clickEvent}
    >
      <g fill={fillColor}>
        <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0016 9.5 6.5 6.5 0 109.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
      </g>
    </svg>
  );
};

export const Solar = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M13 7H11V8H10V10H11V11H13V10H14V8H13V7Z"/>
        <path d="M11 13H13V14H14V16H13V17H11V16H10V14H11V13Z"/>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 2C5.44772 2 5 2.44772 5 3V21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V3C19 2.44772 18.5523 2 18 2H6ZM7 4V8H8V10H7V14H8V16H7V20H11V19H13V20H17V16H16V14H17V10H16V8H17V4H13V5H11V4H7Z"
        />
      </g>
    </svg>
  );
};

export const Substract = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path d="M21 11.001H3v2h18v-2z"/>
      </g>
    </svg>
  );
};

export const ZoomCursor = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM13 11H19V13H13V19H11V13H5V11H11V5H13V11Z"
        />
      </g>
    </svg>
  );
};

export const HoverCursor = (props) => {
  ColorSwitch(props.color);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g fill={fillColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24 0H0V24H24V0ZM4 20V4H20V20H4ZM4 1H1V4H3V3H4V1ZM10.6523 1H6.60901V3H10.6523V1ZM17.391 1H13.3477V3H17.391V1ZM23 1H20V3H21V4H23V1ZM1 6.60901V10.6523H3V6.60901H1ZM23 10.6523V6.60901H21V10.6523H23ZM1 13.3477V17.391H3V13.3477H1ZM23 17.391V13.3477H21V17.391H23ZM3 20H1V23H4V21H3V20ZM23 23V20H21V21H20V23H23ZM6.60901 23H10.6523V21H6.60901V23ZM13.3477 23H17.391V21H13.3477V23Z"
        />
      </g>
    </svg>
  );
};