// Base URL
const baseURL =
  process.env.NODE_ENV === "development"
    ? "/"
    : "https://api.napenergiaterkep.hu/";

// Routes
const mailchimp = `${baseURL}mc/`;

// Used urls in API calls
export const urls = {
  // Mailchimp
  mcSubscribe: `${mailchimp}subscribe`,     // Subscribe
  mcUnsubscribe: `${mailchimp}unsubscribe`, // Unsubscribe
  mcMemberInfo: `${mailchimp}memberinfo`,   // Get member information
  mcAddLead: `${mailchimp}addlead`,         // Add public lead
};