// Cookies Import
import Cookies from "universal-cookie";
import { setSubscribed, setIsAdmin } from "@data";

// Initialize cookies
const cookies = new Cookies();

// Domain option
const domain = process.env.NODE_ENV === "development"
  ? "localhost"
  : process.env.REACT_APP_NETLIFY
    ? "napenergiaterkep.netlify.app"
    : "napenergiaterkep.hu";

// Onboarding
export const cookieSetOnboarding = () => {
  cookies.set("userOnboarded", true, { domain: domain });
};
export const cookieGetOnboarding = () => {
  return cookies.get("userOnboarded", { domain: domain });
};

// Subscribe
export const cookieSetSubscribed = (email, cities) => {
  cookies.set("userSubscribed", { email: email, cities: cities }, { domain: domain });
  setSubscribed({ email: email, cities: cities });
};
export const cookieGetSubscribed = () => {
  return cookies.get("userSubscribed", { domain: domain });
};
export const cookieRemoveSubscribed = () => {
  cookies.remove("userSubscribed", { domain: domain });
};

// AdminHash
export const cookieSetAdminHash = (hash) => {
  cookies.set("adminHash", hash, { domain: domain });
  setIsAdmin(true);
};
export const cookieGetAdminHash = () => {
  return cookies.get("adminHash", { domain: domain });
};