import React from "react";

// INPUT
export const Field = (props) => {
  return (
		<input
			className={props.style}
			type={props.type}
			autoComplete={props.autocomplete}
			name={props.name}
			value={props.value}
			placeholder={props.placeholder}
			onChange={props.onChange}
			required={props.required}
		/>
	);
};
