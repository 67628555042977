import React, {useEffect} from "react";

// Cookie
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";

// State
import { GlobalStore, setCookies } from "@data";
import { useSnapshot } from "valtio";

// Components
import { Done, Cookie, ArrowRight } from "@icons";


export const CookiePanel = () => {
	const { welcomeModal } = useSnapshot(GlobalStore);
	const cookieName = "netCookies";

	// Get initial value
	useEffect(() => {
    const cookieValue = getCookieConsentValue(cookieName);
		setCookies(cookieValue);
  }, []);

  return (
    <CookieConsent
      disableStyles={true}
      containerClasses={"cookie " + (welcomeModal ? "hidden" : "")}
			contentClasses="cookie__body"
			buttonWrapperClasses="cookie__button"
      buttonText={<Done/>}
      cookieName={cookieName}
      onAccept={() => setCookies(true)}
    >
			<div className="cookie__body__icon">
				<Cookie color="white" />
			</div>
			<div className="cookie__body__content">
				<span className="span--bold">
					A weboldal sütiket használ a felhasználói élmények növeléséhez.
				</span>
				<a
					className="cookie__body__content__link"
					href="/Cookie_szabalyzat-napenergiaterkep.pdf"
					target="_blank"
					rel="noreferrer"
				>
					<p>További információk</p>
					<ArrowRight color="orange"/>
				</a>
			</div>
    </CookieConsent>
  );
};