// OL Tile
import TileLayer from "ol/layer/Tile";
import TileImage from 'ol/source/TileImage';
import XYZ from "ol/source/XYZ";

// OL Vector
import VectorLayer from "ol/layer/Vector";
import VectorSource from 'ol/source/Vector';

import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import {createXYZ} from 'ol/tilegrid';
import MVT from 'ol/format/MVT';
import { Stroke, Fill, Style } from 'ol/style';


// BACKGROUND LAYER
export const backroundLayer = new TileLayer({
  source: new TileImage({
    url: 'https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}@2x.png',
  }),
  className: 'bw-layer',
  opacity: 0.5,
});

// CITY FOOTPRINT LAYER
// Create pattern canvas element
const canvas = document.createElement('canvas');
canvas.width = 10;
canvas.height = 10;
const ctx = canvas.getContext('2d');
ctx.strokeStyle = 'rgba(255, 138, 0, 0.5)';
ctx.lineWidth = 3;
ctx.beginPath();
ctx.moveTo(0, 10);
ctx.lineTo(10, 0);
ctx.stroke();

// Create a CanvasPattern object from the canvas element
const pattern = ctx.createPattern(canvas, 'repeat');

// Style function
function cityFootprintStyle(feature) {
  const featureStyle = [
    new Style({
      stroke: new Stroke({
        color: [255, 138, 0, 1],
        width: 4,
      }),
      fill: new Fill({
        color: feature.available ? [255, 138, 0, 0.2] : pattern,
      }),
    })
  ];
  return featureStyle;
}

export const cityFootprintLayer = new VectorLayer({
  source: new VectorSource(),
  style: cityFootprintStyle,
});


// IMAGE LAYER (Solar and Orto)
export const imageLayer = new TileLayer({
  source: new XYZ({
    url: null,
    crossOrigin: 'anonymous',
    maxZoom: 18,
  }),
  minZoom: 11,
  name: "imageLayer",
});

// FOOTPRINT DATA LAYER
const  buildingFootprintStyle = new Style({
  fill: new Fill({
    color: 'transparent',
  }),
  stroke: new Stroke({
    color: 'transparent',
  }),
});

export const buildingFootprintLayer = new VectorTileLayer({
  source: new VectorTileSource({
    tilePixelRatio: 1,
    tileGrid: createXYZ({
      maxZoom: 17,
      crossOrigin: 'anonymous',
    }),
    format: new MVT(),
    url: null,
  }),
  style: buildingFootprintStyle,
  minZoom: 17,
});



// HIGHLIGHT LAYER (hover state)
const highlightStyle = [
  new Style({
    stroke: new Stroke({
      color: [255, 255, 255, 1],
      width: 6,
    }),
  }),
  new Style({
    stroke: new Stroke({
      color: [69, 69, 84, 1],
      lineDash: [4, 6],
      width: 3,
    }),
  })
];

export const highlightLayer = new VectorLayer({
  source: new VectorSource(),
  style: highlightStyle,
});


// SELECTION LAYER
const selectionStyle = [
  new Style({
    stroke: new Stroke({
      color: [255, 255, 255, 1],
      width: 8,
    }),
  }),
  new Style({
    stroke: new Stroke({
      color: [10, 10, 41, 1],
      width: 3,
    }),
  })
];

export const selectionLayer = new VectorLayer({
  source: new VectorSource(),
  style: selectionStyle,
});