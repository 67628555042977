import React, { useEffect } from "react";
import { useSnapshot } from "valtio";

// Components
import { ZoomCursor, HoverCursor } from "@icons";

// State
import {
  GlobalStore,
  setOnboardingStage
} from "@data";

// Cookie import
import {
  cookieGetOnboarding,
  cookieSetOnboarding
} from "@api";



// ONBOARDING TOOLTIP
export const Onboarding = () => {
	const { cookies, onboardingStage } = useSnapshot(GlobalStore);


  // Set cookie to remember onboarding
  useEffect(() => {
    if (cookies && cookieGetOnboarding()) {
      setOnboardingStage(2);
    } else if (onboardingStage === 2 && !cookieGetOnboarding()) {
      cookieSetOnboarding();
    }
  }, [cookies, onboardingStage]);


  return cookies && onboardingStage < 2 && (
    <div className="onboarding">
      <div className="onboarding__body">
        <div className="onboarding__body__icon">
          {onboardingStage === 0
            ? <ZoomCursor color="white" />
            : <HoverCursor color="white" />
          }
        </div>
        <div className="onboarding__body__content">
          <span className="span--bold">
            {onboardingStage === 0
              ? "A kívánt épület megtalálásához kattintson a térképre, vagy használja a keresőt."
              : "Kattintson egy bekeretezett épületre, hogy megnyissa a hozzá tartozó adatokat."
            }
          </span>
        </div>
      </div>
    </div>
  );
};