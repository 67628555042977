import React, { useEffect, useCallback } from "react";
import { useSnapshot } from "valtio";

import { Extent, Add, Substract} from "@icons";
import { GlobalStore, MapConfig } from "@data";


// MAP CONTROLS
export const ZoomControls = ({ map }) => {
  const { mapHomeing } = useSnapshot(GlobalStore);

  // Animate function
  const animateView = useCallback((options) => {
    const view = map.getView();
    view.animate(options);
  },[map]);

  // Zoom in and out
  const setZoom = (delta) => {
    animateView({
      zoom: map.getView().getZoom() + delta,
      duration: 200,
    });
  };

  // Zoom to initial extent
  const setMaxExtent = useCallback(() => {
    animateView({
      center: MapConfig.baseViewWMCenter,
      zoom: MapConfig.initZoom,
      duration: 600,
    });
  },[animateView]);

  // Map homeing effect
  useEffect(() => {
    if (mapHomeing > 0) {
      setMaxExtent();
    }
  }, [mapHomeing, setMaxExtent]);

  if (!map) return;
  return (
    <div className="map-controls">
      <button
        onClick={setMaxExtent}
        aria-label="Tékép alaphelyzetbe állítása"
      >
        <Extent />
      </button>
      <div>
        <button
          onClick={() => setZoom(+1)}
          aria-label="Nagyítás"
        >
          <Add />
        </button>
        <hr />
        <button
          onClick={() => setZoom(-1)}
          aria-label="Kicsinyítés"
        >
          <Substract />
        </button>
      </div>
    </div>
  );
};