import React from "react";

// CHECKBOX
export const Checkbox = (props) => {
  return (
		<div className="checkbox__wrapper">
			<input
				type="checkbox"
				id={props.id}
				checked={props.checked}
				onChange={props.onChange}
				required={props.required}
			/>
			<label htmlFor={props.id}>
				{props.label}
			</label>
		</div>
	);
};
