import React from "react";

// Components
import { Close, ArrowRight } from "@icons";


// CLOSE BUTTON
export const ButtonClose = (props) => {
  return (
		<button
			className="button__close"
			onClick={() => props.action()}
			title={props.title}
			aria-label={props.title}
		>
			<Close/>
		</button>
	);
};


// CTA BUTTON - Text + Icon
export const ButtonTextIcon = (props) => {

	const interactLink = props.url !== undefined;
	const interactClick = props.action !== undefined;

	const Button = (props) => {
		return (
			<button
				className={"button__cta " + (props.secondary ? "secondary" : "")}
				onClick={interactClick ? () => props.action() : undefined}
				aria-label={props.text}
			>
				<div className="text">
					{props.text || "Tovább"}
				</div>
				<div className="icon">
					{props.icon || <ArrowRight/>} 
				</div>
			</button>
		);
	};

	if(interactLink) {
		return (
			<a
				href={props.url}
				target={props.target || "_self"}
				rel="noreferrer"
			>
				<Button {...props} />
			</a>
		);
	} else {
		return (
			<Button {...props} />
		);
	}
};
