import React from "react";
import { useSnapshot } from "valtio";

// State
import {
	GlobalStore,
	setWelcomeModal
} from "@data";

// Components
import { ButtonTextIcon } from "@molecules";
import { Logo } from "@icons";


// WELCOME MODAL
export const WelcomeModal = () => {
	const { cookies, welcomeModal } = useSnapshot(GlobalStore);

	if (!cookies && cookies !== null) {
		return (
			<div className={"modal " + (!welcomeModal ? "hidden" : "")}>
				<div className="modal__content">
					
					<div className="modal__content__body">
						<figure>
							<Logo/>
						</figure>

						<div className="modal__content__body__copy">
							<h3>Tisztelt Érdeklődő!</h3>
							<div>
								<p>Üdvözöljük a napenergia térképünkön, melyet a Solar ViewPoint Kft. az Envirosense Hungary Kft. közreműködésével fejlesztett és a Magyar Napelem Napkollektor Szövetséggel kötött együttműködési megállapodás keretében az ország számos településére kiterjeszt. Az elérhető települések száma folyamatosan bővül.</p>
								<p>Ingyenesen használható online térképünkkel támogatni szeretnénk a napenergiát hasznosító berendezések széleskörű elterjedését, valamint információt kívánunk adni a beruházás előtt állóknak épületük napenergia potenciáljával kapcsolatosan.</p>
								<p>Az oldalon feltüntetett adatok tájékoztató jellegűek, a specifikus településrendezési, építési szabályzatok rendelkezéseire, valamint az áramszolgáltatói előírásokra nem terjednek ki.</p>
								<p>Kellemes böngészést kívánunk!</p>
							</div>
						</div>
					</div>

					<ButtonTextIcon
						action={setWelcomeModal}
						text="Tovább a Napenergia Térképre"
						secondary
					/>
				</div>
			</div>
		);
	}
};