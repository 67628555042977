import React, { useRef, useState } from "react";
import { ButtonTextIcon, Checkbox, Field, OptionsSelector } from "@molecules";
import { ArrowLeft, Done } from "@icons";
import { GlobalStore } from "@data";
import { useSnapshot } from "valtio";
import { urls } from "@api";

import coverSM from "@images/upsell-sm.jpg";
import coverSVP from "@images/upsell-svp.jpg";


// UPSELLBOX
const UpsellBox = () => {
  const { selectedBuildingAddress } = useSnapshot(GlobalStore);
  const upsellFormRef = useRef();
  const [requestConsent, setRequestConsent] = useState(false);
  const [leadStatus, setLeadStatus] = useState("");

  const intrestData = {
    types: [
      "Napelemes rendszert szeretnék",
      "Tervező/kivitelező vagyok"
    ],
    features: [
      "Tájékoztatást kérek a lehetőségeimről",
      "3D szimulációs tervezést igényelnék"
    ]
  };

  // Form Data
  const [formData, setFormData] = useState({
    intrestType: 0,
    intrestFeature: 0,
    intrestSelected: false,
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
  });

  // Handle input change
  const handleInput = (e) => {
    setFormData(prevData => {
      const {name, value, type, checked} = e.target;
      return {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      };
    });
  };

  const handleOptionsSelection = (e) => {
    // Prevent form to default function
    e.preventDefault();

    setFormData(prevData => {
      return {
        ...prevData,
        intrestSelected: !prevData.intrestSelected,
      };
    });
  };

  const handleRequestConsentChange = () => setRequestConsent(!requestConsent);

  // Handle user lead
  const handleLead = async (e) => {
    e.preventDefault();

    setLeadStatus("sending");

    fetch(`${urls.mcAddLead}?email=${formData.email}&lastName=${formData.lastName}&firstName=${formData.firstName}&phone=${formData.phone}&address=${selectedBuildingAddress}`)
    .then(res => res.json())
    .then(res => {
      if(res.id) {
        console.log(res.id);
        setLeadStatus("requested");
      }
    })
    .catch(err => {
      console.log(err);
    });
  };

  return (
    <div
      ref={upsellFormRef}
      className="panel__upsellbox"
    >
      {!formData.intrestSelected ? (
        <>
          <div className="panel__upsellbox__head">
            <h3>Többre van szüksége egy becslésnél?</h3>
            <p>Pontos számításokért, részletesebb adatokért ajánljuk figyelmébe személyre szabott szolgáltatásainkat.</p>
          </div>

          <form onSubmit={handleOptionsSelection}>

            <div className="formcontent">
              <OptionsSelector
                title="Kérjük válasszon érdeklődésének megfelelően!"
                options={intrestData.types}
                name="intrestType"
                onChange={handleInput}
                value={formData.intrestType}
              />
              {Number(formData.intrestType) === 0 &&
                <OptionsSelector
                  title="Miben tudunk segíteni?"
                  options={intrestData.features}
                  name="intrestFeature"
                  onChange={handleInput}
                  value={formData.intrestFeature}
                />
              }
            </div>

            <ButtonTextIcon
              type="submit"
              value="submit"
            />
          </form>
        </>
      ) : Number(formData.intrestType) === 0 ? Number(formData.intrestFeature) === 0 ? (
        <>
          <div className="panel__upsellbox__head">
            <button className="panel__upsellbox__back"
              onClick={handleOptionsSelection}
            >
              <ArrowLeft color="white"/>
              <p>Vissza</p>
            </button>
            <h3>Ismerje meg lehetőségeit!</h3>
          </div>

          {leadStatus !== "requested" ? (
            <>
              <form onSubmit={handleLead}>

                <div className="formcontent">
                  <h5>Kérjük adja meg adatait!</h5>
                  <div className="formcontent--fields">
                    <div>
                      <Field
                        style="dark"
                        type="text"
                        autocomplete="family-name"
                        name="lastName"
                        value={formData.lastName}
                        placeholder="Vezetéknév"
                        onChange={handleInput}
                        required
                      />
                      <Field
                        style="dark"
                        type="text"
                        name="firstName"
                        autocomplete="given-name"
                        value={formData.firstName}
                        placeholder="Keresztnév"
                        onChange={handleInput}
                        required
                      />
                    </div>
                    <Field
                      style="dark"
                      type="email"
                      name="email"
                      value={formData.email}
                      placeholder="E-mail cím"
                      onChange={handleInput}
                      required
                    />
                    <Field
                      style="dark"
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      placeholder="Telefonszám"
                      onChange={handleInput}
                    />
                  </div>
                </div>

                <Checkbox
                  onChange={handleRequestConsentChange}
                  checked={requestConsent}
                  id="requestConsent"
                  label={<span dangerouslySetInnerHTML={{__html: "<a href='/Adatkezelesi_Tajekoztato-latest.pdf' target='_blank'>Adatkezelési tájékozatót</a> elolvastam és az abban foglaltakat elfogadom."}} />}
                  required
                />

                {leadStatus !== "sending"
                  ? (
                    <ButtonTextIcon
                      text="Érdeklődés elküldése"
                      type="submit"
                      value="submit"
                    />
                  ) : (
                    <div className="form-loader">
                      <span>Folyamatban...</span>
                    </div>
                  )
                }
              </form>
            </>
          ) : (
            <>
              <div className="panel__upsellbox__success">
                <Done/>
                <h5>Érdeklődését rögzítettük</h5>
              </div>
              <p>A megadott elérhetőségeken hamarosan felvesszük Önnel a kapcsolatot.</p>
            </>
          )}
        </>
      ) : (
        <>
          <div className="panel__upsellbox__head">
            <button className="panel__upsellbox__back"
              onClick={handleOptionsSelection}
            >
              <ArrowLeft color="white"/>
              <p>Vissza</p>
            </button>
            <h3>Megoldás a SUNME!</h3>
          </div>

          <div className="panel__upsellbox__content">
            <div
              className="panel__upsellbox__content--cover"
              style={{ backgroundImage: `url(${coverSM})`}}
            ></div>
            <p>Készítse el 3D szimulációs számítását partnerünk, a SUNME segítségével. A tudományos alapokon nyugvó 3D szimuláció a tervezésnél figyelembe veszi többek között az egész éves időjárást, valamint az épített és természetes árnyékoló tényezőket is. A SUNME segítségére lesz a pályázat előkészítésében, valamint a leendő kivitelező kiválasztásában is.</p>
          </div>

          <ButtonTextIcon
            text="Tudjon meg többet"
            url="https://sunme.hu/registration?utm_source=envirosense"
            target="_blank"
          />
        </>
      ) : (
        <>
          <div className="panel__upsellbox__head">
            <button className="panel__upsellbox__back"
              onClick={handleOptionsSelection}
            >
              <ArrowLeft color="white"/>
              <p>Vissza</p>
            </button>
            <h3>A Solar ViewPoint segít, mi már felmértük a tetőket!</h3>
          </div>

          <div className="panel__upsellbox__content">
            <div
              className="panel__upsellbox__content--cover"
              style={{ backgroundImage: `url(${coverSVP})`}}
            ></div>
            <p>Kérje 3D épületmodell termékünket, mellyel készen adjuk az épületek tetőfelületeinek paramétereit, valamint igény esetén a tervezendő napelemek elhelyezési vázrajzát is. Termékeinkkel a tervezők, kivitelezők javítani tudják értékesítési, tervezési folyamataikat, ezáltal pénzt és időt tudnak megtakarítani.</p>
          </div>

          <ButtonTextIcon
            text="Tudjon meg többet"
            url="https://solarvp.hu/"
            target="_blank"
          />
        </>
      )}
    </div>
  );
};

export default UpsellBox;