import { Done } from "@icons";
import React from "react";

// OptionsSelector
export const OptionsSelector = ({ title, options, name, onChange, value }) => {
  return (
    <div className="options-selector">
      <h5>{title}</h5>
      <div className="options-selector__options">
        {options.map((item, index) => {
          return (
            <div
              key={index}
              className={`option--wrapper ${index === Number(value) ? "active" : ""}`}
            >
              <label>
                <input
                  type="radio"
                  name={name}
                  value={index}
                  checked={index === Number(value)}
                  onChange={onChange}
                />
                <div className="option__content">
                  <div className="option__content--icon">
                    <Done color="orange"/>
                  </div>
                  <p>{item}</p>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
